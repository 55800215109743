<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar title="" class="nav-bar">
				<template #left>
					<van-image src="img/home.png" @click="back()" style="width: 32px;" />
				</template>
				<template #right>
					<van-image src="img/kefu.png" @click="showServiceOnline()" style="width: 32px;" />
				</template>
			</van-nav-bar>
		</div>
		<div class="ifrmae_page">
			<iframe width="100%" height="80vh" frameborder="0" id="iframe_web"
				:src="this.$store.getters.getBaseInfo.renzheng !==undefined ?this.$store.getters.getBaseInfo.renzheng:this.$store.getters.getBaseInfo.kefu"></iframe>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			back() {
				this.$router.push("Home");
			},
			showServiceOnline() {
				this.$router.push({
					path: '/ServiceOnline'
				})
			}
		},
		created() {

		},
		mounted() {
			/**
			 * iframe-宽高自适应显示
			 */
			const oIframe = document.getElementById('iframe_web');
			const deviceHeight = document.documentElement.clientHeight;
			// oIframe.style.height = '80vh'; //数字是页面布局高度差
			oIframe.style.height = (Number(deviceHeight)-65) + 'px'; //数字是页面布局高度差

		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";
</style>