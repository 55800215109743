<template>
	<div class="page">
		<van-nav-bar :title="xuanfeidata.xuanfei_name" class="nav-bar newnavbar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="feiziInfo">
			<div class="topInfo">
				<div><span>{{ this.xuanfeidata.xuanfei_name }}</span></div>
				<div>{{$t('home.hight')}}: {{ this.xuanfeidata.hight }}cm {{$t('home.bust')}}：{{ this.xuanfeidata.bust }}</div>
				<div class="tags" v-if="this.xuanfeidata.flag">
					<div class="van-tag--large van-tag--large">{{ this.xuanfeidata.flag.split(',')[0] }}</div>
					<div class="van-tag--large van-tag--success">{{ this.xuanfeidata.flag.split(',')[1] }}</div>
					<div class="van-tag--large van-tag--danger">{{ this.xuanfeidata.flag.split(',')[2] }}</div>
					<div class="van-tag--large van-tag--warning">{{ this.xuanfeidata.flag.split(',')[3] }}</div>
				</div>
				<div>
					<span style="color: rgb(107, 34, 179); font-size: 14px;">{{$t('home.price_space')}}：</span>
					<div class="van-rate" tabindex="0" role="radiogroup">
						<div v-for="star in totalStars" :key="star" role="radio" tabindex="0" :aria-setsize="totalStars"
							:aria-posinset="star" :aria-checked="star <= currentRating" class="van-rate__item">
							<i class="van-icon van-icon-star van-rate__icon"
								:class="{ 'van-rate__icon--full': star <= currentRating, 'van-rate__icon--empty': star > currentRating }"
								style="font-size: 18px;">
							</i>
						</div>
					</div>
				</div>
				<div style="color: rgb(107, 34, 179); font-size: 14px; margin: 4px 0px;">{{$t('home.location')}}： </div>
				<div class="yuyueBox" @click="yuyue()">{{$t('home.booking')}}</div>
				<div class="dianzanimg" @click="dianzan()">
					<div class="van-image" style="width: 60px; height: 60px;">
						<img src="img/xuanfei/zan.png" class="van-image__img" style="object-fit: cover;">
					</div>
					<p style="color: rgb(107, 34, 179); margin: 0px;">{{xuanfeidata.dianzan}}</p>
				</div>
			</div>
			<div class="cenInfo">
				<van-tabs animated sticky line-width="100px" :swipeable="true">
					<van-tab :title="$t('home.picture')" style="color: rgb(202, 51, 88);">
						<div class="imgswiper">
							<div v-for="(v, index) in xuanfeidata.img_url" :key="index"
								style="width: 100px; height: 100px; margin-right: 10px;">
								<van-image :src="v" class="imgI van-image__img"
									:class="{ active: activeIndex === index }" style="object-fit: cover;"
									@click="viewImg(index)"></van-image>
							</div>
						</div>
						<div v-if="this.xuanfeidata.img_url">
							<van-image :src="xuanfeidata.img_url[activeIndex]"></van-image>
						</div>
					</van-tab>
					<van-tab :title="$t('home.video')" style="color: rgb(54, 52, 217);">
						<div class="videoBox"></div>
					</van-tab>
				</van-tabs>
			</div>

			<div class="btmInfo">
				<div style="color: rgb(42, 20, 104); margin-bottom: 10px; font-weight: bold;">
					{{$t('home.introduce')}}</div>
				<div style="color: rgb(83, 69, 137);">{{xuanfeidata.introduce}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			totalStars: 5, // 总星星数量
			currentRating: 5, // 当前评分值，这里假设默认是5
			xuanfeidata: {},
			activeIndex: 0, // 初始化未选中任何图片
		};
	},
	methods: {
		back() {
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
			window.history.go(-1);
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.currentRating = res.data.number;
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			// this.$toast(this.$t('choose.content_manage'));
			this.$router.push({
				path: '/Game'
			})

		},
		dianzan() {
			this.$http({
				method: 'get',
				url: 'dianzan',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata.dianzan = res.data;
			});
		},


		viewImg(index) {
			this.activeIndex = this.activeIndex === index ? -1 : index;
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style>
.newnavbar {
	background: linear-gradient(30deg, #7d76ef, #d63f8c) !important;
}

.feiziInfo {
	background: #ebedf0;
}

.feiziInfo .topInfo {
	position: relative;
}

.feiziInfo>div {
	padding: 4vw 20px;
	background: #fff;
	margin-bottom: 4vw;
}

.feiziInfo .topInfo>div:first-child {
	font-weight: 600;
}

.feiziInfo .topInfo>div:first-child>span:first-child {
	color: #2c1868;
	font-size: 5vw;
}

.feiziInfo .topInfo>div:nth-child(2) {
	color: #6b22b3;
	font-size: 3vw;
	margin: 10px 0;
}

.feiziInfo .topInfo>div:nth-child(3) {
	width: 50%;
}

.feiziInfo .topInfo>div:nth-child(3)>div {
	background: linear-gradient(50deg, #9b54ca, #e6557f);
	color: #fff;
	display: inline-block;
	border-radius: 10px;
	margin-right: 25px;
	font-size: 22px;
	padding: 18px;
	margin-bottom: 25px;
}

.feiziInfo .topInfo .yuyueBox {
	position: absolute;
	right: 4vw;
	bottom: 20px;
	color: #fff;
	padding: 20px 30px;
	background: linear-gradient(20deg, #e73266, #ee5380);
	border-radius: 7vw;
}

.feiziInfo .topInfo .dianzanimg {
	position: absolute;
	top: 4vw;
	right: 7vw;
	text-align: center;
}

.feiziInfo .cenInfo .imgswiper {
	width: 100%;
	overflow-x: scroll;
	margin: 4vw 0;
	display: flex;
	flex-wrap: nowrap;
}

.van-tab {
	font-size: 30px;
	line-height: 30px;
}

.van-tabs--line .van-tabs__wrap {
	height: 50px;
}

.feiziInfo .cenInfo .imgswiper>div .imgI.active {
	border: 7px solid #e54878;
}

.van-image__error,
.van-image__img,
.van-image__loading {
	display: block;
	width: 100%;
	height: 100%;
}

.van-rate__icon--full {
	/* 当星星被选中时的样式 */
	color: rgb(255, 210, 30);
	/* 星星选中时的颜色 */
}

.van-rate__icon--empty {
	/* 当星星未被选中时的样式 */
	color: #e6e2e2;
	/* 星星未选中时的颜色为白色 */
}
</style>
