<template>
  <div class="container page">
    <van-nav-bar :title="$t('mine.enter_bank_card_info')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{$t('mine.please_enter_bank_card_info')}}</div>
      <van-cell-group>
        <van-field v-model="real_name" :label="$t('mine.real_name')" type="text" :placeholder="$t('mine.please_enter_real_name')" />
        <van-field v-model="bankinfo" :label="$t('mine.bank_name')" type="text" :placeholder="$t('mine.please_enter_bank_name')" />
        <van-field v-model="branch_name" :label="$t('mine.branch_name')" type="text" :placeholder="$t('mine.please_enter_branch_name')" />
        <van-field v-model="branch_code" :label="$t('mine.branch_code')" type="digit" :placeholder="$t('mine.please_enter_branch_code')" />
        <van-field v-model="bankid" :label="$t('mine.bank_card_no')" type="digit" :placeholder="$t('mine.please_enter_bank_card_no')" />
      </van-cell-group>
      <p>{{$t('mine.bank_card_tips')}}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{$t('mine.sure_bind_bank_card')}}</van-button>
    <!-- <van-popup v-model="showBank" round position="bottom">
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBank:false,
      userInfo:{},
      real_name:"",
      bankinfo:"",
      branch_name:"",
      branch_code:"",
      bankid:""
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.real_name == ""){
        this.$toast(this.$t('mine.please_enter_real_name'));
        return true;
      }
      if(this.bankinfo == ""){
        this.$toast(this.$t('mine.please_enter_bank_name'));
        return true;
      }
      if(this.branch_name == "" ){
        this.$toast(this.$t('mine.please_enter_branch_name'));
        return true;
      }
      if(this.branch_code == ""){
        this.$toast(this.$t('mine.please_enter_branch_code'));
        return true;
      }
      if(this.bankid == ""){
        this.$toast(this.$t('mine.please_enter_bankid'));
        return true;
      }

      this.$http({
        method: 'post',
        data:{
          real_name:this.real_name,
          bankinfo:this.bankinfo,
          branch_name:this.branch_name,
          branch_code:this.branch_code,
          bankid:this.bankid
        },
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel,::v-deep .van-picker__confirm {
  padding: 0 20px;
  font-size: 5vw;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
}
</style>
