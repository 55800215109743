<template>
  <div class="mine page">
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="header">
          <van-nav-bar class="nav-bar">
            <template #right>
              <van-icon name="home-o" @click="tohome()" color="#fff" />
            </template>
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <van-image round class="user_img" :src="this.userInfo.header_img">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <div class="login-content">
              <p class="login-btn">{{ this.userInfo.username }}</p>
              <!-- <p class="login-label">{{this.userInfo.ip}}</p> -->
            </div>
          </div>
        </div>
        <div class="content">
          <div class="finance">
            <!-- <div class="finance-item"  @click="doPay()">
              <van-icon class="icon" style="" name="peer-pay" />
              <span class="text">{{$t('mine.recharge')}}</span>
            </div>
            <div class="line"></div> -->
            <div class="finance-item" @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">{{ $t('mine.withdraw') }}</span>
            </div>
          </div>
          <div v-if="this.userInfo.money" class="wallet">
            <div class="part-1 van-hairline--bottom">
              <p class="flex-1 font-28 font-primary-color">{{ $t('mine.my_wallet') }}</p>
              <!-- <span class="font-28 font-gray">{{$t('mine.details')}}</span>
                  <van-icon class="font-gray" style="font-size: 28px" name="arrow" /> -->
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ this.userInfo.money }}</p>
              <span class="font-28 font-gray">{{ $t('mine.use_balance') }}({{ $t('lottery.huobi') }})</span>
              <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
            </div>
          </div>
          <div :style="{ marginTop: menu_top + 'px' }" class="menu">
            <div class="menu-item" @click="$router.push({ path: '/Infomation' });">
              <van-image class="menu-item-icon" src="img/mine/nav1.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.basic_info') }}</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/SetLoginPassword' });">
              <van-image class="menu-item-icon" src="img/mine/nav7.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.login_password') }}</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
              <van-image class="menu-item-icon" src="img/mine/baobiao.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('lottery.task_record') }}</span>
            </div>
            <div class="menu-item" @click="toNotice()">
              <van-image class="menu-item-icon" src="img/mine/4.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.notice_center') }}</span>
            </div>
            <div class="menu-item" @click="toService()">
              <van-image class="menu-item-icon" src="img/mine/5.png">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t('mine.customer_service') }}</span>
            </div>



            <!-- <div class="menu-item" @click="$router.push({path:'/Infomation'});">
                <van-image class="menu-item-icon" src="img/mine/user.svg">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t('mine.user_center')}}</span>
              </div> -->
          </div>
          <van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{$t('mine.loginout')}}</van-button>
        </div>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      userInfo: {
      },
      menu_top: 40,
      isLoading: false,
    };
  },
  methods: {
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.$toast(this.$t('fresh_success'));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    exit() {
      this.$toast(this.$t('mine.enter_after_task_finish'));
    },
    loginout(){
        localStorage.clear();
        this.$router.push({path:'/Login'});
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    tohome() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Home' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          this.$toast(this.$t('refresh_success'));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doPay() {
      this.$toast(this.$t('lottery.not_enough_balance'));
      return false;
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.$router.push({path:'/Withdraw'})
          } else {
            this.$router.push({path:'/Setbank'})
            this.$toast.fail(this.$t('mine.set_review_card'));
          }
        } else {
          // this.$toast.fail(this.$t('res.data.msg'));
        }

        
      })
    },
    toService() {
      console.log(this.$store.getters.getBaseInfo.iskefu);
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t('mine.service_offline'));
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t('video.account_offline'));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    } else {
      this.userInfo.username = this.$t('mine.login_register');
      this.userInfo.ip = this.$t('mine.more_services');
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.mine {
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
  background: url(/img/mine/usertop.png) no-repeat top -16vw center;
  background-size: contain;
}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.nav-bar {
  background: local;
  /* background: transparent; */
}

.mine .header {
  /* background: linear-gradient(90deg,#7e5678,#e6c3a1); */
  padding-bottom: 100px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}

.mine .user_img {
  height: 130px;
  width: 130px;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 30px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}

.mine .header .user-wrapper .login-content .login-label {
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0, 0%, 100%, .6);
}

.mine .page-bg {
  height: 500px;
  /* background: linear-gradient(90deg,#7e5678,#e6c3a1); */
}

.mine .content {
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  margin-left: 30px;
  font-size: 30px;
  color: #432989;
  font-weight: 600;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 50px;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
  width: 33.33%;
  margin-bottom: 10px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 3vw;
  font-weight: 500;
  text-align: center;
  display: block;
  color: #432788;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 3vw;
  width: 8vw;
  height: 8vw;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
  margin: 3vw auto;
}

.mine .wrapper .content .wallet {
  margin-top: 11vw;
  padding: 0 4vw;
  border-radius: 25px;
  background: #a06098;
  color: #fff;
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 15vw;
  color: #fff;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #fff;
}

.font-gray {
  color: #fff;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 15vw;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 8vw;
  font-weight: 700;
  color: #fff;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e6c3a1;
}

.sign-out {
  margin: 11vw 0 0 0;
  height: 12vw;
  width: 100%;
  line-height: 12vw;
  border-radius: 25px;
  color: hsl(0, 0%, 100%);
  font-size: 4vw;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #6529c9, #cc2996);
}
</style>
