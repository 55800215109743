<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('game.title')" class="nav-bar">
      </van-nav-bar>
    </div>
    <div class="banner">
		<swiper-slide v-for="(v, key) in banners" :key="key">
			<van-image class="banner_img" :src="v.url">
				<template v-slot:loading>
					<van-loading type="circular" />
				</template>
			</van-image>
		</swiper-slide>
	</div>
    <van-button class="login-btn" type="primary" size="normal" @click="toServicePage()">
      {{ $t('login.title')}}
    </van-button>
  </div>
</template>

<script>
export default {
	data() {
		return {
			banners: [{}],
		};
	},
	methods: {
		back(){
			this.$router.push("Game");
		},
		toServicePage(){
			this.$router.push("JumpPage");
		},
		getBanner(data) {
			this.banners = data.banners;
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.basicData = res.data;
				this.getBanner(this.basicData); //获取banner
			})
		},
	},
	created() {
		this.toServicePage();
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.servicelistItem{
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 30px 30px;
  margin: 30px 20px;
  border-radius: 20px;
  justify-content: space-between;
  background: #fff;
}
.servicelistItem .servicelistItemTop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}
.servicelistItem .servicelistItemTop .servicelistItemImage{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.servicelistItem .servicelistItemTop .servicelistItemText{
  margin-left: 50px;
  font-size: 43px;
  font-weight: 700;
  color: #000;
  flex: 1;
}
.servicelistItem .servicelistItemTop .servicelistItemBtn{
  display: flex;
  width: 150px;
  height: 55px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}
.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText{
  color: #fff;
  font-size: 25px;
}
.servicelistItem .servicelistItemBottom{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #f2f2f5;
  border-radius: 10px;
  color: #979799;
}
.servicelistItem .servicelistItemBottom .servicelistItemInfoText{
  font-size: 30px;
}
.login-btn {
  height: 13.333vw;
  border-radius: 6.667vw;
  color: #fff;
  background: linear-gradient(90deg, #6529c9, #cc2996);
  font-size: 4vw;
  font-weight: bolder;
  border: none;
  margin: 11.333vw 4vw;
}
</style>
